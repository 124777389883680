import { AppBrands } from "@common/enums/appBrands.enum";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CustomerSearchForm } from "@ops/components/CustomerSearchForm/CustomerSearchForm";
import { CustomerSearchTable } from "@ops/components/CustomerSearchTable/CustomerSearchTable";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(5)};
  max-width: 1920px;
  width: 100%;
`;

export const CustomerSearchPage = () => {
  const [brand, setBrand] = useState<AppBrands | "All">("All");
  const [search, setSearch] = useState<string>("");

  return (
    <Container>
      <H1>Search Customers</H1>
      <BoCard>
        <CustomerSearchForm setSearch={setSearch} setBrand={setBrand} />
      </BoCard>
      <CustomerSearchTable brand={brand} search={search} />
    </Container>
  );
};
