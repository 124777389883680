import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const TableWrapper = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export const ResultCountContainer = styled(Body2).attrs({
  as: "div",
  fontWeight: "Bold",
})``;
