import { IdType } from "@common/types/apiTypes";
import { useCustomer } from "@ops/hooks/useCustomer";
import { useParams } from "react-router-dom";

export const useCustomerBrand = () => {
  const { id } = useParams<{ id: IdType }>();
  const { customer, error } = useCustomer(id);

  if (!customer || error) {
    return null;
  }

  return customer.brand;
};
