import { AppBrands } from "@common/enums/appBrands.enum";
import { ajax } from "@common/services/ajax";
import { CustomerSearchResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  ContactLogType,
  CreateContactLogType,
} from "@ops-utils/types/contactLogTypes";
import { EventLogResponse } from "@ops-utils/types/eventLogTypes";

export interface LegacyCustomerSearchApiSearchOptions {
  brand?: AppBrands;
  search: string;
}

const BASE_PATH = "/api/customers";

export const legacyCustomerApi = {
  contactLogs: {
    create: (
      customerId: IdType,
      contactLog: CreateContactLogType
    ): Promise<void> => {
      return ajax.post(`${BASE_PATH}/${customerId}/contact-logs/`, contactLog);
    },
    list: (customerId: IdType): Promise<ContactLogType[]> => {
      return ajax.get(`${BASE_PATH}/${customerId}/contact-logs/`);
    },
  },
  eventLogs: {
    list: (customerId: IdType): Promise<EventLogResponse> => {
      return ajax.get(`${BASE_PATH}/${customerId}/event-logs/`);
    },
  },
  search: {
    list: (searchOptions: LegacyCustomerSearchApiSearchOptions) => {
      return ajax.get<CustomerSearchResponseType>(`${BASE_PATH}/search/`, {
        params: searchOptions,
      });
    },
  },
};
