import { AppBrandNameMap, AppBrands } from "@common/enums/appBrands.enum";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as ChevronDown } from "@ops-design-system/icons/ChevronDown.svg";
import { borders } from "@ops-design-system/styles/borders";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import styled from "styled-components";

const MIN_SEARCH_TERM_LENGTH = 3;

interface CustomerSearchFormProps {
  setBrand: Dispatch<SetStateAction<AppBrands | "All">>;
  setSearch: Dispatch<SetStateAction<string>>;
}

const InputWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(2)};
  margin-bottom: ${rhOpsSpacingPx(2)};
  width: 100%;

  > :first-child {
    flex: 1;
  }
`;

const BrandSelectWrapper = styled.div`
  align-items: center;
  border: ${borders["100"]};
  border-radius: 5px;
  display: flex;
  position: relative;
  width: 150px;

  svg {
    position: absolute;
    right: ${rhOpsSpacingPx(1.5)};
    transform: translateY(-3px);
    z-index: 0;
  }

  &:focus-within,
  &:hover {
    border-color: ${darkPurple[500]};
    cursor: pointer;
  }
`;

const BrandSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: 0;
  flex: 1;
  padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(2)};
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const Container = styled.div``;

const ActionWrapper = styled.div`
  display: inline-block;
`;

const HelpTextContainer = styled.div`
  margin-top: ${rhOpsSpacingPx(2)};
  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;

const HelpText = styled(Body3).attrs({ as: "div" })``;

export const CustomerSearchForm = (props: CustomerSearchFormProps) => {
  const { setBrand, setSearch } = props;

  const [query, setQuery] = useState<string>("");

  const ready = query?.trim().length >= MIN_SEARCH_TERM_LENGTH;

  const handleSubmit = useCallback(
    (e: FormEvent<EventTarget>) => {
      e.preventDefault();
      setSearch(query);
    },
    [query, setSearch]
  );

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <BoTextInput
            name="query"
            placeholder="e.g. 'smith' or 'id:123'"
            autoFocus
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <BrandSelectWrapper>
            <BrandSelect
              onChange={(e) => {
                setBrand(e.target.value as AppBrands);
              }}
            >
              <option value="All">All Brands</option>
              {Object.values(AppBrands).map((brand) => (
                <option key={brand} value={brand}>
                  {AppBrandNameMap[brand]}
                </option>
              ))}
            </BrandSelect>
            <ChevronDown />
          </BrandSelectWrapper>
        </InputWrapper>
        <ActionWrapper>
          <BoButton size="small" disabled={!ready} type="submit">
            Search
          </BoButton>
        </ActionWrapper>
      </form>
      <HelpTextContainer>
        <HelpText>
          Your query term will be matched against ESI ID, first name, last name
          and email and any partial matches will be shown in the table below.
          ESI ID matches will be exact matches. Name and email will use a
          partial (substring) match. e.g. Search for “jon” will return customers
          named “Jonathan” and customers with emails like
          “mynameisjon@example.com”.
        </HelpText>
        <HelpText>To search by customer ID, use the syntax “id:123”</HelpText>
        <HelpText>
          You must include at least 3 characters in your search term.
        </HelpText>
      </HelpTextContainer>
    </Container>
  );
};
