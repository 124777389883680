import { AppBrands } from "@common/enums/appBrands.enum";
import { ReactComponent as EnergyTexasLogo } from "@common/images/energyTexasRhapsodyLogo.svg";
import { ReactComponent as RhythmLogo } from "@common/images/rhythmRhapsodyLogo.svg";
import {
  darkPurple,
  energyTexas,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import { useCustomerBrand } from "@ops/hooks/useCustomerBrand";
import React from "react";

interface BrandInformation {
  backgroundColor: string;
  id: string;
  logo: React.ReactNode;
}

export const useBrandInformation = (): BrandInformation => {
  const brand = useCustomerBrand();

  switch (brand) {
    case AppBrands.RHYTHM: {
      return {
        backgroundColor: darkPurple[100],
        id: AppBrands.RHYTHM,
        logo: <RhythmLogo />,
      };
    }
    case AppBrands.ENERGY_TEXAS: {
      return {
        backgroundColor: energyTexas.light,
        id: AppBrands.ENERGY_TEXAS,
        logo: <EnergyTexasLogo />,
      };
    }
    default: {
      return {
        backgroundColor: trueWhite,
        id: "default",
        logo: <div>RHAPSODY</div>,
      };
    }
  }
};
