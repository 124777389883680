import { AppBrandNameMap, AppBrands } from "@common/enums/appBrands.enum";
import { CustomerSearchType } from "@common/types/apiResponseTypes";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import {
  EnergyTexasTheme,
  RhythmTheme,
} from "@design-system/brandTheme/brandTheme";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { trueWhite } from "@ops-design-system/styles/palette.colors";
import {
  ResultCountContainer,
  TableWrapper,
} from "@ops/components/CustomerSearchTable/CustomerTable.styled";
import { premiseCustomerPath } from "@ops/routes/routePaths";
import React from "react";
import { Link } from "react-router-dom";

type CustomerTableRowProps = {
  customer: CustomerSearchType;
};

const CustomerTableRow = ({ customer }: CustomerTableRowProps) => {
  const opsDashboardLink = premiseCustomerPath(
    customer.id,
    customer.primaryPremiseId,
    "#customer"
  );

  return (
    <BoTableRow data-testid={`customerTable__row:${customer.id}`}>
      <BoTableCell
        $align="center"
        $backgroundColor={
          customer.brand === AppBrands.RHYTHM
            ? RhythmTheme.colors.primary.main
            : EnergyTexasTheme.colors.primary.main
        }
        $textColor={trueWhite}
      >
        {AppBrandNameMap[customer.brand]}
      </BoTableCell>
      <BoTableCell $align="center">
        <Link to={opsDashboardLink}>{customer.id}</Link>
      </BoTableCell>
      <BoTableCell $align="center">{customer.fullName}</BoTableCell>
      <BoTableCell $align="center">
        {formatPhoneNumber(customer.phone)}
      </BoTableCell>

      <BoTableCell $align="center">
        <Link
          to={opsDashboardLink}
          data-testid={`customerTable__emailLink:${customer.id}`}
        >
          {customer.email}
        </Link>
      </BoTableCell>
      <BoTableCell $align="center">{customer.status}</BoTableCell>
      <BoTableCell $align="center">{customer.primaryEsiId}</BoTableCell>
      <BoTableCell $align="center">
        {customer.primaryServiceAddress.deliveryLine1}{" "}
        {customer.primaryServiceAddress.city}{" "}
        {customer.primaryServiceAddress.zipCode}
      </BoTableCell>
      <BoTableCell $align="center">{customer.meterCount}</BoTableCell>
    </BoTableRow>
  );
};

interface ResultCountProps {
  count: number;
}

const ResultCount = ({ count }: ResultCountProps) => {
  return (
    <ResultCountContainer>
      {count} Customer{count > 1 ? "s" : ""} Found
    </ResultCountContainer>
  );
};

interface CustomerTableProps {
  customers: CustomerSearchType[];
}

export const CustomerTable = (props: CustomerTableProps) => {
  const { customers } = props;

  return (
    <TableWrapper>
      <ResultCount count={customers.length} />
      <BoTable>
        <BoTableHead data-testid="customerTableHeader">
          <BoTableRow>
            <BoTableHeadCell>Brand</BoTableHeadCell>
            <BoTableHeadCell>ID</BoTableHeadCell>
            <BoTableHeadCell>Name</BoTableHeadCell>
            <BoTableHeadCell>Phone Number</BoTableHeadCell>
            <BoTableHeadCell>Email</BoTableHeadCell>
            <BoTableHeadCell>Status</BoTableHeadCell>
            <BoTableHeadCell>ESI-ID</BoTableHeadCell>
            <BoTableHeadCell>Service Address</BoTableHeadCell>
            <BoTableHeadCell># of Meters</BoTableHeadCell>
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {customers.map((customer: CustomerSearchType) => (
            <CustomerTableRow customer={customer} key={`row-${customer.id}`} />
          ))}
        </BoTableBody>
      </BoTable>
    </TableWrapper>
  );
};
