import { BO_TABLE_CORNER_RADIUS } from "@ops-design-system/components/BoTable/BoTable.constants";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { borders } from "@ops-design-system/styles/borders";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

type Alignments = "left" | "right" | "center";

export const BoTableLabel = styled(Body2).attrs({ as: "h4" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const BoTableCaption = styled(BoTableLabel).attrs({ as: "caption" })`
  text-align: left;
`;

export const BoTable = styled.table`
  border: ${borders["100"]};
  border-radius: ${BO_TABLE_CORNER_RADIUS};
  border-spacing: 0;
  padding: 0;
  width: 100%;
`;

export const BoTableHead = styled.thead``;

export const BoTableCell = styled(Body2).attrs({ as: "td" })<{
  $align?: Alignments;
  $backgroundColor?: string;
  $textColor?: string;
}>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "transparent"};
  border: 1px solid transparent;
  border-left: ${borders["100"]};
  border-spacing: 0;
  color: ${({ $textColor }) => $textColor || grey["900"]};
  padding: ${rhOpsSpacingPx(0.65)} ${rhOpsSpacingPx(2)};
  text-align: ${({ $align }) => $align || "left"};

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: 0;
  }
`;

export const BoTableRow = styled.tr`
  &:nth-of-type(even) {
    background-color: ${grey["50"]};
  }
`;

// Sizes table cell to width of contents instead of default table cell widths
export const BoTableCellContentWidth = styled(BoTableCell)`
  white-space: nowrap;
  width: 1%;
`;

export const BoTableBody = styled.tbody``;

export const BoTableHeadCell = styled(Body3).attrs({
  as: "th",
})<{ $align?: Alignments; $isClickable?: boolean; $width?: number }>`
  border-bottom: ${borders["100"]};
  border-spacing: 0;
  color: ${grey["600"]};
  cursor: ${({ $isClickable }) => ($isClickable ? "pointer" : "auto")};
  font-weight: ${FontWeight.SemiBold};
  padding: ${rhOpsSpacingPx(0.5)} ${rhOpsSpacingPx(2)};
  text-align: ${({ $align }) => $align || "center"};
  width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
`;
