import {
  LegacyCustomerSearchApiSearchOptions,
  legacyCustomerApi,
} from "@ops-data/api/legacyCustomerApi";
import { CustomersSearchQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseCustomersSearchQueryProps {
  queryOptions: {
    enabled: boolean;
  };
  searchOptions: LegacyCustomerSearchApiSearchOptions;
}

export const useCustomersSearchQuery = (
  props: UseCustomersSearchQueryProps
) => {
  const { queryOptions, searchOptions } = props;

  return useQuery({
    queryFn: () => legacyCustomerApi.search.list(searchOptions),
    queryKey: CustomersSearchQueryKeys.list(searchOptions),
    ...queryOptions,
  });
};
